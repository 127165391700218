import React from "react";
import classes from './HomeScreen.module.scss'
import { Header } from "../../components/Header/Header";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import img0 from '../../img/222.webp'
import sauna from '../../img/sauna.svg'
import forest from '../../img/forest.svg'
import bed from '../../img/bed.svg'
import silent from '../../img/silent.svg'
import leaf from '../../img/leaf.svg'
import { Map } from "../../components/Map/Map";
import BookPlace from "../../components/BookPlace/BookPlace";
import { Link } from "react-scroll";
import Footer from "../../components/Footer/Footer";
import Price from "../../components/Price/Price";
import img1 from '../../img/1_a.webp' 
import img2 from '../../img/2_a.webp' 
import img3  from '../../img/3_a.webp' 
import img4 from '../../img/4_a.webp' 
import img5 from '../../img/5_a.webp' 
import img6 from '../../img/6_a.webp' 
import img7 from '../../img/7_a.webp' 
import img8 from '../../img/8_a.webp' 
import img9  from '../../img/9_a.webp' 
import img10 from '../../img/10_a.webp' 
import img11  from '../../img/11_a.webp' 
import img12 from '../../img/12_a.webp' 
import img13 from '../../img/13_a.webp' 
import img14 from '../../img/14_a.webp'
import img15 from '../../img/15_a.webp'
import img16 from '../../img/16_a.webp'
import img17 from '../../img/17_a.webp'
import img18 from '../../img/18_a.webp'
import img19 from '../../img/19_a.webp'
import img20 from '../../img/20_a.webp'
import img21 from '../../img/21_a.webp'
import img22 from '../../img/22_a.webp'
import img23 from '../../img/23_a.webp'
import img24 from '../../img/24_a.webp'
import img25 from '../../img/25_a.webp'
import img26 from '../../img/26_a.webp'
import img27 from '../../img/27_a.webp'
import img28 from '../../img/28_a.webp'
import img29 from '../../img/29_a.webp'
export const HomeScreen = () => {
    return(
        <div className={classes.home}>
            <div className="g-wrapperScreens">
                <Header />
                <section className={classes.home__main}>
                   <div className="g-container">
                        <div className={classes.home__main__wrapper}>
                            <h1 className={classes.home__main__wrapper_h1}>Ваши незабываемые выходные на хуторе у живописной реки СУЛА в деревне Янушки</h1>
                            <h2 className={classes.home__main__wrapper_h2}>Проведите выходные на природе в объятиях леса и реки</h2>
                            <Link 
                                className={classes.home__main__wrapper_button}
                                to="BlockBook"
                                smooth={true}
                                offset={-70}
                                duration={500}>
                                    Забронировать
                            </Link>
                            <p className={classes.home__main__wrapper_bel}>Калi ласка, запрашаем да нас, паважаныя госцi !!!</p>
                        </div >
                   </div>
                </section>
                <section className={classes.home__about}>
                    <div className="g-container">  
                        <div className={classes.home__about__box}> 
                            <div className={classes.home__about__box__item}>
                                <p className={classes.home__about__box__item_title}>Расстояние</p>  
                                <p className={classes.home__about__box__item_descr}> Недалеко от  Минска   (58 км от МКАД)</p>
                            </div> 
                            <div className={classes.home__about__box__item}>
                                <p className={classes.home__about__box__item_title}>Климат</p>  
                                <p className={classes.home__about__box__item_descr}>У нас всегда тепло: отопление с погодозависимой автоматикой</p>
                            </div>
                            <div className={classes.home__about__box__item}>
                                <p className={classes.home__about__box__item_title}>Река</p>  
                                <p className={classes.home__about__box__item_descr}>Одна из самых чистых рек Беларуси - река Сула - в 70 метрах от дома </p>
                            </div>
                            <div className={classes.home__about__box__item}>

                                <p className={classes.home__about__box__item_title}>Баня</p>  
                                <p className={classes.home__about__box__item_descr}>Русская баня из соснового сруба на дровах с настоящим мокрым паром  </p>

                            </div>
                        </div>
                    </div>
                </section>
                <section className={classes.home__abouthome} id="BlockAbout">
                    <img src={leaf} className={classes.home__abouthome_img} alt="Лист"/>
                    <div className="g-container" >
                        <div className={classes.home__abouthome__wrapper}>
                            <img src={img0} alt="Избушка в Янушках первое фото" className={classes.home__abouthome__wrapper__img}/>
                            <div className={classes.home__abouthome__wrapper__block}>
                                <p className={classes.home__abouthome__wrapper__block_title}>О Доме</p>
                                <div className={classes.home__abouthome__wrapper__block__test} >
                                    <div className={classes.home__abouthome__wrapper__block__test__dscr} > 
                                        <div className={classes.home__abouthome__wrapper__block__test__dscr__box}> 
                                            <img src={forest} className={classes.home__abouthome__wrapper__block__test__dscr__box_img} alt='sauna'/>
                                            <p>Хутор расположен в лесном массиве</p>
                                        </div> 
                                        <div className={classes.home__abouthome__wrapper__block__test__dscr__box2}> 
                                        <img src={sauna} className={classes.home__abouthome__wrapper__block__test__dscr__box_img} alt='sauna'/>
                                            <p>На территории баня и озеро</p>
                                        </div> 
                                    </div>
                                    <div className={classes.home__abouthome__wrapper__block__test__dscr} > 
                                        <div className={classes.home__abouthome__wrapper__block__test__dscr__box}> 
                                        <img src={bed} className={classes.home__abouthome__wrapper__block__test__dscr__box_img} alt='sauna'/>
                                            <p>6 спальных мест</p>
                                        </div> 
                                        <div className={classes.home__abouthome__wrapper__block__test__dscr__box2}> 
                                            <img src={silent} className={classes.home__abouthome__wrapper__block__test__dscr__box_img} alt='sauna'/>
                                            <p>Тишина и спокойствие</p>
                                        </div> 
                                    </div>
                                    
                                </div>
                                <p className={classes.home__abouthome__wrapper__block_price}></p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className={classes.home__aboutHomeMore}>
                    <div className="g-container">
                        <div className={classes.home__aboutHomeMore__wrapper}>
                            <div className={classes.home__aboutHomeMore__wrapper__text}>
                                <p className={classes.home__aboutHomeMore__wrapper__text_title}>
                                Усадьба располагается на хуторе, в деревне Янушки (Брестское направление) 40 минут езды от кольцевой дороги в сторону г.п. Рубежевичи. Дом площадью 78 м. кв. , состоящий из 2 комнат и 6 спальных мест. Внутренняя обстановка напоминает квартиру – студию: из небольшой прихожей попадаешь в просторную комнату с компактной кухонной зоной. Далее идет вторая небольшая комната. Сан. узел. оборудован всем необходимым: унитаз с подогревом сиденья + биде. В доме имеется печь-камин, LCD телевизор (триколор), холодильник, микроволновая печь, полноценная кухня, предоставляется постельной белье, полотенца. Имеется мангал и угли для шашлыка.
                                </p>
                                <p className={classes.home__aboutHomeMore__wrapper__text_title_bel}></p>
                            </div>
                            <img alt='house outside' className={classes.home__aboutHomeMore__wrapper_img} src={require('../../img/aboutHome2.jpg')}/>
                        </div>
                    </div>
                </section>
                <Price />
               <section className={classes.home__photoBlock} id="BlockPhoto">
                    <div className="g-container">
                        <div className={classes.home__photoBlock__wrapper}>
                            <Carousel className={classes.home__photoBlock__wrapper__slider}>
                                
                                <div>
                                    <img src={img1} alt="природа"/>
                                </div>

                                <div>
                                    <img src={img2} alt="два"/>
                                </div>

                                <div>
                                    <img src={img3} alt="три"/>
                                </div>

                                <div>
                                    <img src={img4} alt="четыр"/>
                                </div>
                                <div>
                                    <img src={img5} alt="пять"/>
                                </div>
                                <div>
                                    <img src={img6} alt="шесть"/>
                                </div>
                                <div>
                                    <img src={img7} alt="сем"/>
                                </div>
                                <div>
                                    <img src={img8} alt="восем"/>
                                </div>
                                <div>
                                    <img src={img9} alt="девяд"/>
                                </div>
                                <div>
                                    <img src={img10} alt="десят"/>
                                </div>
                                <div>
                                    <img src={img11} alt="один"/>
                                </div>
                                <div>
                                    <img src={img12} alt="двен"/>
                                </div>
                                <div>
                                    <img src={img13} alt="тренд"/>
                                </div>
                                <div>
                                    <img src={img14} alt="четыр"/>
                                </div>
                                <div>
                                    <img src={img15} alt="пят"/>
                                </div>
                                <div>
                                    <img src={img16} alt="шет"/>
                                </div>
                                <div>
                                    <img src={img17} alt="сем"/>
                                </div>
                                <div>
                                    <img src={img18} alt="воссем"/>
                                </div>
                                <div>
                                    <img src={img19} alt="девят"/>
                                </div>
                                <div>
                                    <img src={img20} alt="двад"/>
                                </div>
                                <div>
                                    <img src={img21} alt="двадодин"/>
                                </div>
                                <div>
                                    <img src={img22} alt="двадва"/>
                                </div>
                                <div>
                                    <img src={img23} alt="дватри"/>
                                </div>
                                <div>
                                    <img src={img24} alt="двачетыр"/>
                                </div> 
                                <div>
                                    <img src={img25} alt="двапять"/>
                                </div>
                                <div>
                                    <img src={img26} alt="двашет"/>
                                </div>
                                <div>
                                    <img src={img27} alt="двасемь"/>
                                </div>
                                <div>
                                    <img src={img28} alt="двавоссемь"/>
                                </div>
                                <div>
                                    <img src={img29} alt="двадевядь"/>
                                </div>
                            </Carousel>
                        </div>
                    </div>
               </section>
               <Map />
               <BookPlace />
               <Footer />
            </div>
        </div>
    )
}